@font-face {
    font-family: 'Poppins-Light';
    src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}