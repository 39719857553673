.default-1 {
  border-radius: 20px;
  min-width: 60px;
  border: none;
  height: 34px;
  margin-right: 10px;
  background-color: rgb(var(--main-color));
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  color: #fff;
  padding: 0 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 34px;
  &:hover {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8);
  }
  i {
    margin-left: 10px;
  }
}

.default-2 {
  border-radius: 20px;
  min-width: 80px;
  border: none;
  height: 28px;
  margin-right: 10px;
  background-color: rgb(var(--main-color));
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  &.big {
    height: 34px;
  }
  &.fixed {
    width: 84px;
  }
  &:hover {
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8);
  }
  i {
    margin-right: 5px;
  }
  &.disabled {
    background: #4667bc;
  }
  &.off {
    background: #ccc;
    pointer-events: none;
    &:hover {
      background: darken(#ccc, 10%);
    }
  }
  &.red {
    &.off {
      background: #ccc;
      pointer-events: none;
    }
    margin-right: 0;
    background: rgb(var(--red));
    &:hover {
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.8);
    }
  }
}

.default-3 {
  border-radius: 20px;
  width: 28px;
  background-color: #272e40;
  color: #fff;
  border: none;
  height: 28px;
}
.default-4 {
  background: transparent;
  color: rgb(var(--red));
  border: none;
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
}
.default-5 {
  background: transparent;
  border: none;
  color: $dark-blue;
}
.btn-page {
  border-color: $dark-blue;
  background: $dark-blue;
  color: #fff;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  border: none;
  cursor: pointer;
  &.disabled {
    background-color: #ccc;
    color: #fff;
    cursor: initial;
  }
}
