/* You can add global styles to this file, and also import other style files */
@import '/src/styles/fonts';
@import '/src/styles/modals';
@import '/src/styles/colors';
@import 'ngx-toastr/toastr';
@import '/src/styles/toggle-switch';

:root {
  --main-color: '0 0 0';
  --secondary-color: '0 0 0';
  --darkgray: '0 0 0';
  --lightgray: '0 0 0';
  --red: '0 0 0';
}

.ngx-toastr {
  &.toast-error {
    background-color: rgb(189 48 57);
  }
}

.tags-textarea > * {
  --tag-text-color: #fff;
  --tag-hover: #fff;
}

tag {
  cursor: text;
}

tag * {
  cursor: text;
}

html {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
}
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: #f3f5f8;
}

h1 {
  margin: 0;
  font-size: 20px;
  margin-bottom: 10px;
}
.c-black {
  color: #333 !important;
}
input,
select,
textarea {
  &:focus {
    outline: none;
  }
}
button {
  cursor: pointer;
}
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #333 #dfe9eb;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-track:active {
  background-color: #b8c0c2;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #333;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #333;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #333;
}

//ng multiselect overwrite
.multiselect-dropdown .dropdown-btn {
  border-radius: 0 !important;
  border-color: #e2e2e2 !important;
  min-height: 30px;
  padding: 3px 8px !important;
  font-size: 14px;
}
.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  top: 58% !important;
}

.multiselect-dropdown
  .dropdown-btn
  .dropdown-multiselect--active
  .dropdown-multiselect__caret:before {
  top: 85% !important;
}

.dropdown-list {
  border-radius: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  box-shadow: 1px 3px 4px #00000029 !important;
  overflow: hidden;
  li {
    font-size: 14px;
  }
  .filter-textbox {
    padding: 4px 10px !important;
    input {
      padding-left: 0 !important;
    }
  }
}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border-radius: 20px !important;
  padding: 0 17px !important;
  background: rgb(var(--main-color)) !important;
  border: 1px solid rgb(var(--main-color)) !important;
  max-width: fit-content !important;
  margin-top: 2px;
}

.no-filtered-data h5 {
  font-size: 16px;
  margin: 0;
  font-weight: normal;
  color: var(--main-color);
}
