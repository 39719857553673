@import '/src/styles/colors';
@import '/src/styles/btns';

.modal-dialog {
  max-width: 780px;
}
.modal-container {
  font-family: 'Poppins-Regular', Arial, Helvetica, sans-serif;
  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    padding: 15px 20px;
    p {
      font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
      font-size: 20px;
      margin: 0;
    }
    button {
      background-color: transparent;
      border: none;
    }
  }
  .content-wrapper {
    display: flex;
    justify-content: center;
    margin: 10px auto;
    min-width: 600px;
    max-width: 90%;
    > div {
      width: 100%;
      margin-bottom: 40px;
    }
    .content-wrapper-col {
      padding: 0 20px;
      min-width: 200px;
    }
    span {
      color: rgb(var(--main-color));
    }
    input {
      border: 1px solid #e2e2e2;
      height: 30px;
      width: 100%;
      color: #333;
      padding: 0 10px;
    }
    select {
      padding: 0 10px;
      color: #333;
      width: 100%;
      height: 30px;
      cursor: pointer;
      border: 1px solid #e2e2e2;
    }
    .input-line {
      margin-bottom: 5px;
    }
    .input-file-line {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-file-wrapper {
        display: flex;
        align-items: center;
        img {
          height: auto;
          margin-left: 20px;
          max-width: 100px;
          max-height: 80px;
        }
        .img-empty-container {
          border: 1px dashed #ccc;
          width: 80px;
          height: 80px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #333;
          font-size: 12px;
        }
      }
    }

    .title {
      span {
        font-size: 14px;
        color: #333;
      }
      .blue {
        color: rgb(var(--main-color));
        font-size: 16px;
        margin-left: 18px;
      }
    }
    .input-check-lines-wrapper {
      height: 450px;
      overflow-y: auto;
      .input-check-line {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 4px 0;
        &:nth-child(odd) {
          background: #f2f2f2;
        }
        cursor: pointer;
        span {
          display: block;
          line-height: 16px;
          color: #333;
        }
        input[type='checkbox'] {
          width: 20px;
          margin: 0 15px;
        }
      }
    }

    .pager {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      .current {
        min-width: 50px;
        text-align: center;
        span {
          color: #333;
        }
      }
    }
  }
  .btns-containers {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px 20px;
  }
}
b {
  font-family: 'Poppins-Bold', Arial, Helvetica, sans-serif;
}
textarea {
  border: 1px solid #e2e2e2;
  width: 100%;
  height: 110px;
  padding: 5px 10px;
}
